.payment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.form-title {
  position: relative;
  font-size: 32px;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.separator-line {
  height: 1px;
  background-color: black;
  width: 100%;
}

.info-text {
  color: #cec3c3;
  font-size: 16px;
  padding: 10px;
  font-weight: 400;
  text-align: center;
}

.form-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 25px;
}

.form-fields__input,
.form-fields__select {
  background-color: rgb(20, 20, 20);
  color: #fff;
  border-radius: 20px;
  height: 60px;
  width: 100%;
  max-width: 500px;
  border: none;
  outline: none;
  padding: 12px;
  font-size: 1.2em;

  &__phone {
    display: none;

    &__shown {
      display: inline-block;
    }
    
    &::before{
      content: +7;
    }
  }
}

.form-fields__input--error,
.form-fields__select--error {
  border: 1px solid red;
}

.form-fields__select {
  background-color: rgb(20, 20, 20);
  color: gray;
}
