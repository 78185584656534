.arena-breakout-shop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.solid-divider {
  border: 1px solid rgb(31, 31, 31);
  width: 75%;
}
