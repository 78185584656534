.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;
  width: 100%;
}

.header__trovo-shop-logo {
  max-width: 82px;
  height: auto;
  cursor: pointer;
}

.navbar__list {
  display: flex;
  gap: 20px;
  list-style: none;
}

.list__item {
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: rgba(93, 241, 79, 0.792);
  }
}
