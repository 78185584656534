.submit-button {
  padding-top: 30px;
}

.submit-button__btn {
  width: 160px;
  height: 60px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: 20px;
  background-color: #4ab279;
  color: #fff;
  transition: 500ms linear;

  &:hover {
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: scale(1.1);
  }
}
