.elixir-card {
  width: 100%;
  max-width: 160px;
  height: 250px;
  background: rgb(31, 31, 31);
  border-radius: 30px;
  cursor: pointer;
  transition: 500ms linear;

  &:hover {
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: scale(1.1);
  }

  @media (min-width: 576px) {
    min-width: 200px;
  }

  @media (min-width: 744px) {
    min-width: 220px;
    height: 260px;
  }

  @media (min-width: 1024px) {
    min-width: 280px;
    height: 370px;
    padding: 20px;
  }
}

.elixir-card__img {
  width: 100%;
  height: 144px;
  object-fit: contain;

  @media (min-width: 1024px) {
    height: 170px;
  }
}

.elixir-card__bottom {
  display: grid;
  gap: 5px;
  padding: 15px;

  @media (min-width: 1024px) {
    padding-left: 20px;
    gap: 15px;
  }
}

.elixir-card__text {
  color: #fff;
  font-size: 14px;

  @media (min-width: 744px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  &--gray {
    color: rgb(173 163 163);
    font-weight: bold;
  }

  &--cost {
    font-size: 12px;
    font-weight: bold;

    @media (min-width: 744px) {
      font-size: 18px;
    }

    @media (min-width: 1024px) {
      font-size: 25px;
    }
  }
}

.elixir-card__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
