.other-game-card {
  position: relative;
}

.other-game-card__wallpaper {
  position: relative;
  width: 250px;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;

  @media (min-width: 576px) {
    width: 200px;
    height: 250px;
  }

  @media (min-width: 744px) {
    width: 220px;
    height: 280px;
  }

  @media (min-width: 1024px) {
    width: 285px;
    height: 350px;
  }

  @media (min-width: 1280px) {
    width: 287px;
    height: 350px;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: inset 0px -66px 104px 5px #000000;
}

.other-game-card__img {
  position: relative;
  z-index: 1;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.other-game-card__logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.other-game-card__title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 3;
}

.other-game-card__text {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
