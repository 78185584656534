.fill-balance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}

.fill-balance__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 100%;

  @media (min-width: 744px) {
    padding: 40px;
  }

  @media (min-width: 1024px) {
    width: 60%;
  }

  @media (min-width: 1280px) {
    width: 40%;
  }
}

.fill-balance__type {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  width: 100%;
  border-radius: 20px;
  background-color: #1f1f1f;
  padding: 20px;
  cursor: pointer;
  transition: 500ms linear;
  text-decoration: none;

  &:hover {
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    transform: scale(1.05);
  }

  &--pubg {
    background: linear-gradient(90deg, rgb(12 11 11) 25%, rgb(57 52 43) 100%);
  }

  &--trovo {
    background: -webkit-linear-gradient(left, #000002, #260166);
    background: -moz-linear-gradient(left, #000002, #260166);
    background: -ms-linear-gradient(left, #000002, #260166);
    background: -o-linear-gradient(left, #000002, #260166);
    background: linear-gradient(to right, #000002, #260166);
  }

  &--undawn {
    background: linear-gradient(
      90deg,
      rgb(34 30 30) 27%,
      rgb(246 121 0 / 22%) 100%
    );
  }

  &--arena {
    background: linear-gradient(
      90deg,
      rgb(45 45 45) 53%,
      rgb(78, 73, 73) 79%,
      rgb(147 139 139) 100%
    );
  }
}

.fill-balance__logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  transform: scale(1.5);
}

.fill-balance__text {
  color: #fff;
  font-size: 26px;

  @media (min-width: 744px) {
    font-size: 42px;
  }
}
