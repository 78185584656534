.modal {
  height: 100vh;
  min-height: 400px;
  min-width: fit-content;
  font-family: "Inter", sans-serif;

  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  width: 90%;
  padding: 24px;
  max-width: 500px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  width: fit-content;
  margin-right: auto;
  margin-bottom: 5px;
}

.title__secondary {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 5px;
}

.order {
  padding: 12px;
  border-radius: 10px;
  background: rgba(0, 21, 53, 0.05);
  font-size: 12px;
}

.order__container {
  width: 100%;
}

.hr {
  margin-top: 5px;
  margin-bottom: 5px;
  border-color: rgba(0, 21, 53, 0.1);
}
.summary {
  display: flex;
  justify-content: space-between;
}

.summary__amount {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}

.payment__container {
  width: 100%;
}

.payment__list {
  list-style: none;

  display: flex;
  justify-content: center;
  gap: 20px;
}

.broker {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 70px;
  transition: 0.3s linear;
  box-shadow: 0 2px 0 #f9f9f9;
  border: 1px solid #cdcdcd;
  background-color: white;
  position: relative;

  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }
  &__selected {
    border: 1px solid #5e46f5;
  }
}

.broker__image {
  display: block;
  width: 100%;
  max-width: 80px;
  height: 100%;
  margin: auto;

  object-fit: contain;
}

.broker__price {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 12px;
  color: #888;
}

.phone__container {
  width: 100%;
}

.input {
  min-height: 40px;
  padding: 12px;
  display: none;

  width: 100%;
  overflow: hidden;
  outline: none;
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
  background: #fff;
  font-family: "Inter", sans-serif;

  transition: 0.3s linear;
  &__shown {
    display: inline-block;
  }

  &:before {
    content: "+7";
  }
}

.submit {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding: 12px 35px;
  width: 100%;
  border-radius: 10px;
  background: #5e46f5;
  cursor: pointer;
  outline: none;
  border: none;

  &:active,
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.logo {
  width: 27px;
  height: 26px;
}

.footer {
  display: flex;
  gap: 10px;
  margin-top: 25px;
}

.footer__text {
  color: #525e72;
  font-size: 8px;
  font-weight: 400;
  line-height: 150%; /* 12px */
}
