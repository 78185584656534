.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.popup-wrapper--visible {
  display: block;
  overflow-y: scroll;
  cursor: pointer;
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 20px;
  background-color: #202020;
  border-radius: 5px;
  width: 90%;
  max-width: 780px;
  min-height: 550px;
  overflow: auto;
  border-radius: 20px;
}
