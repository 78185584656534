.payment-types {
  @media (min-width: 1024px) {
    justify-content: space-evenly;
    max-width: 500px;
    width: 100%;
    gap: 50px;
  }
}

.payment-types__type {
  background-color: #fff;
  border-radius: 10px;
}

.form-fields__input,
.form-fields__select {
  height: 50px;
  width: 100%;
  max-width: 500px;
  border: none;
  outline: none;
  padding: 12px;
  font-size: 1.2em;
}

.payment-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  width: 100%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.form-title {
  position: relative;
  font-size: 20px;
  color: #000;
  font-weight: 700;
  text-align: center;
}

.separator-line {
  height: 1px;
  background-color: black;
  width: 100%;
}

.info-text {
  color: #757575;
  font-size: 16px;
  padding: 10px;
  font-weight: 400;
  text-align: center;
}

.form-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 25px;
}

.form-fields__input,
.form-fields__select {
  height: 50px;
  width: 100%;
  max-width: 500px;
  border: none;
  outline: none;
  padding: 12px;
  font-size: 1.2em;
}

.form-fields__input--error,
.form-fields__select--error {
  border: 1px solid red;
}

.form-fields__select {
  height: 60px;
  border-radius: 20px;
  background-color: rgb(20, 20, 20);
  color: gray;
}
