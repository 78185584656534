.accordion-wrapper {
  width: 100%;
  padding: 25px 30px;
  background-color: #212121;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;

  // @media (min-width: 744px) {
  //   min-width: 700px;
  //   justify-content: center;
  //   align-items: center;
  // }

  // @media (min-width: 1024px) {
  //   min-width: 900px;
  // }

  // @media (min-width: 1920px) {
  //   flex-direction: column;
  //   width: 1200px;
  // }

  cursor: pointer;
}

.summary {
  width: 100%;

  &::marker {
    content: none;
  }

  &::before,
  &::after {
    content: "";
  }
}

.summary__title {
  font-size: 24px;
}

.summary__text {
  margin-top: 25px;
  font-size: 20px;
  line-height: 1.55;
  color: #626262;
}

.open-close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  pointer-events: none;
  background-color: #353535;
  transition: 0.3s linear;

  &::before,
  &::after {
    content: "";
    width: 25px;
    border-bottom: 2px solid;
    position: absolute;
    top: 46%;
    right: 19%;
    transition: 0.15s linear;
  }

  &::after {
    transform: rotate(90deg);
  }
}

.accordion-wrapper:hover .open-close-button {
  background-color: #4ab279;

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(135deg);
  }
}
